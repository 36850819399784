import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import abductionIllustration from '../images/abduction-illustration.svg';

function NotFoundPage() {
  return (
    <Layout>
      <SEO title='404: Not found' />
      <div className='text-center'>
        <img
          alt='Ghost getting abducted by aliens'
          className='block mx-auto w-64'
          src={abductionIllustration}
        />
        <h2 className='mt-10 text-primary-blue-900 text-2xl font-bold inline-block'>
          Oups...
        </h2>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
